import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import Section from 'components/Section'
import PageSection from 'components/Section/pageSection'
import PageTransitionLink from 'components/PageTransitionLink'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'
import media from 'styles/media'
import { animatedLinkStyles } from 'styles/animations'
import { Flex } from '@rebass/grid'
import { color } from 'const/colors'
import { links } from 'const'
import 'react-id-swiper/src/styles/css/swiper.css'

const Grabbable = styled.div`
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const StyledPageSection = styled(PageSection)`
  padding: 0 248px;

  ${media.desktop`
    padding: 0 136px;
  `}

  ${media.tablet`
    padding: 0 8px;
  `}

  ${media.phone`
    padding: 0;
  `}
`

const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* padding: 0 248px; */
`

const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 2560px;
  margin: 138px 0 150px;

  ${media.tablet`
    margin: 43px 0 70px 30px;
    justify-content: flex-start;
  `}

  ${media.phone`
    padding: 43px 0 70px 30px;
  `}
`

const Container = styled(Flex)`
  justify-content: space-between;
  width: 100%;

  ${media.tablet`
    justify-content: flex-start;
  `}

  ${media.phone`
    flex-direction: column;
  `}
`

const TextBox = styled.div`
  min-width: 360px;
  height: 369px;
  margin-bottom: 0;

  ${media.phone`
    min-width: auto;
    width: auto;
    height: auto;
    margin-bottom: 34px;
  `}
`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin: 0 0 17px 0;

  ${media.phone`
    font-size: 24px;
    line-height: 40px;
    margin: 0 0 7px 0;
  `}
`

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 40px;
  max-width: 360px;
  margin: 0;

  ${media.phone`
    font-size: 20px;
    line-height: 28px;
  `}
`

const Slide = styled(Section)`
  max-width: 360px;
  min-height: 369px;
  max-height: 369px;
  padding: 56px 32px 58px 40px;
  background-color: ${color.white.o100};

  ${media.tablet`
    padding: 40px 24px 105px 24px;
  `}

  ${media.phone`
    max-width: 264px;
  `}
`

const SlideTop = styled.div`
  min-height: 200px;

  ${media.tablet`
    min-height: 210px;
  `}

  ${media.phone`
    min-height: 220px;
  `}
`

const PageTransitionLinkStyled = styled(PageTransitionLink)`
  font-size: 18px;
  color: ${color.black.o100};
  line-height: 28px;
  cursor: pointer;
  margin-top: 0;
  ${animatedLinkStyles};

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  `}
`

const Intro = styled.p`
  color: ${color.black.o25};
  font-size: 18px;
  line-height: 28px;
  margin: 0;

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;
  `}
`

const Logo = styled.img`
  overflow: hidden;
  margin-bottom: 16px;
  max-height: 30px;
  max-width: 100%;
`

const SwiperContainer = styled.div`
  width: 100%;
  max-width: 1100px;

  ${media.tablet`
    width: 100%;
  `}
`

const renderPartners = (partners) => {
  let partnerElements = []
  partners.map(({ elements: { logo, tagline, slug } }) => {
    const srcLogo = `${logo.value[0].url}?w=300`
    partnerElements = [
      ...partnerElements,
      <Slide key={tagline.value}>
        <SlideTop>
          <Logo src={srcLogo} />
          <Intro margin="5px 0 50px" fontSize="16px" opacity="0.75">
            {tagline.value}
          </Intro>
        </SlideTop>
        {slug.value ? (
          <PageTransitionLinkStyled to={`/partner/${slug.value}`}>
            Learn more &#8594;
          </PageTransitionLinkStyled>
        ) : (
          <PageTransitionLinkStyled to={links.home}>
            Learn more &#8594;
          </PageTransitionLinkStyled>
        )}
      </Slide>,
    ]
  })
  return partnerElements
}

const PartnersSection = ({
  elements: { partners_title, partners_tagline, partners },
  currentBreakpoint,
}) => {
  return (
    <StyledPageSection isBorder bgColor={color.black.o5}>
      <OuterWrapper>
        <OuterContainer>
          <Container>
            <TextBox>
              <Title>{partners_title.value}</Title>
              <Subtitle>{partners_tagline.value}</Subtitle>
            </TextBox>
            <SwiperContainer>
              <Grabbable>
                {currentBreakpoint === 'phone' && (
                  <Swiper
                    {...{
                      slidesPerView: 1.5,
                      spaceBetween: 16,
                      centeredSlides: true,
                      loop: true,
                    }}
                  >
                    {renderPartners(partners)}
                  </Swiper>
                )}

                {(currentBreakpoint === 'tablet' ||
                  currentBreakpoint === 'desktop' ||
                  currentBreakpoint === 'desktopLarge') && (
                  <Swiper
                    {...{
                      slidesPerView: 3,
                      spaceBetween: 32,
                      centeredSlides: false,
                      loop: true,
                      autoplay: {
                        delay: 2500,
                        disableOnInteraction: true,
                      },
                    }}
                  >
                    {renderPartners(partners)}
                  </Swiper>
                )}
              </Grabbable>
            </SwiperContainer>
          </Container>
        </OuterContainer>
      </OuterWrapper>
    </StyledPageSection>
  )
}

PartnersSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(PartnersSection)
