import styled from 'styled-components'
import media from 'styles/media'
import { Flex } from '@rebass/grid'

export const Wrapper = styled.div`
  margin-bottom: 120px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const Photo = styled.div`
  width: 72.32%;

  ${media.tablet`  
    width: 100%;
    padding-right: ${({ inverted }) => !inverted && '30px'};
  `}

  ${media.phone`  
    padding-right: 0;
  `}
`

export const TextSection = styled.div`
  width: 25.16%;
  max-width: 320px;
  padding-right: ${({ inverted }) => !inverted && '10.17%'};
  padding-left: ${({ inverted }) => inverted && '10.17%'};
  margin-right: ${({ inverted }) => inverted && '32px'};
  margin-left: ${({ inverted }) => !inverted && '32px'};

  ${media.tablet`
    padding-right: ${({ inverted }) => !inverted && '30px'};
    padding-left: ${({ inverted }) => inverted && '30px'};
    width: 100%;
  `}

  ${media.phone`
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  `}
`

export const IMG = styled.img`
  width: 100%;
  display: block;

  ${media.phone`
    margin: 0 auto;
  `}
`

export const ProjectContainer = styled(Flex)`
  /*  1272px + 144px */
  max-width: 1416px;
  margin: 0 auto;
  margin-bottom: 130px;
  justify-content: center;

  ${media.tablet`
    margin-bottom: 70px;
    border-bottom: none;
  `};
`

export const ProjectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
  width: 100%;

  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
`
