import styled from 'styled-components'
import PageTransitionLink from 'components/PageTransitionLink'
import { color } from 'const/colors'
import media from 'styles/media'
import { Flex } from '@rebass/grid'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  ${media.phone`
    flex-direction: column;
    align-items: center;
  `}
  padding-top: 40px;
`

export const OfficialPartnerCaption = styled.span`
  font-weight: bold;
  color: ${color.black.o100};
  font-size: 0.8em;
  margin-top: 5px;
`

export const StyledPageTransitionLink = styled(PageTransitionLink)`
  transition: transform 0.2s;
  margin: 20px;
  max-height: 50px;
`

export const AccreditationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.img`
  height: calc(55px ${({ officialPartner }) => officialPartner && '- 1.2em'});
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
`

export const WhatWeDoWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`
