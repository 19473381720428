import React from 'react'
import { objectOf, arrayOf, shape, string } from 'prop-types'
import { Container } from './components/styles'
import Highlight, { HighlightShape } from './components/highlight'
import PageSection from '../../components/Section/pageSection'
import { HighlightContainer } from './components/highlight/styles'
import styled from 'styled-components'
import media from '../../styles/media'

const StyledPageSection = styled(PageSection)`
  padding: 0 248px;

  ${media.desktop`
    padding: 0 136px;
  `}

  ${media.tablet`
    padding: 0 8px;
  `}

  ${media.phone`
    padding: 0;
  `}
`

export const HighlightsContainerWrapper = styled(Container)`
  flex-direction: column;

  ${media.tablet`
    margin: 0 24px;
  `}

  ${media.phone`
    max-width: 412px;
  `}
`

const HighlightsSecton = ({ elements }) => {
  return (
    <StyledPageSection>
      <HighlightsContainerWrapper style={{ flexDirection: 'column' }}>
        {elements.highlights.map((highlight, index) => (
          <HighlightContainer key={highlight.system.id}>
            <Highlight highlight={highlight} inverted={index % 2 !== 0} />
          </HighlightContainer>
        ))}
      </HighlightsContainerWrapper>
    </StyledPageSection>
  )
}

HighlightsSecton.propTypes = {
  elements: objectOf(
    shape({
      highlights: arrayOf(HighlightShape),
      system: objectOf(
        shape({
          name: string,
        })
      ),
    })
  ),
}

export default HighlightsSecton
