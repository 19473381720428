import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from 'components/Container/text'
import media from 'styles/media'
import { black } from 'const/colors'

const Wrapper = styled.div`
  padding-right: ${({ inverted }) => inverted && '50px'};
  padding-left: ${({ inverted }) => !inverted && '50px'};

  ${media.phone`
    padding: 0;
  `}
`

const Title = styled.h1`
  color: ${black};
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin: 0 0 17px 0;

  ${media.phone`
    font-size: 24px;
    line-height: 40px;
    margin: 0 0 7px 0;
  `}
`

const TextStyles = css`
  p {
    color: ${black};
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;

    ${media.tablet`
    font-size: 20px;
    line-height: 28px
    margin: 0;
  `}
  }
`

const HighlightText = ({ title, description }) => {
  return (
    <Wrapper inverted>
      <Title>{title}</Title>
      <Text richText textStyles={TextStyles}>
        {description}
      </Text>
    </Wrapper>
  )
}

export default HighlightText

HighlightText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
