import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section/headerSection'

const HeaderSection = ({
  elements: { title, tagline, cta_text, cta_link, supportingherotext },
  skew,
}) => (
  <Section
    header
    maxWidth="560px"
    headline={title.value}
    tagline={tagline.value}
    buttonText={cta_text.value}
    buttonLink={cta_link.value}
    description={supportingherotext.value}
    tabletMb="38px"
    noMb
    richText
    isColorBackground
    skew={skew}
  />
)

HeaderSection.propTypes = {
  elements: PropTypes.object,
  skew: PropTypes.any,
}

export default HeaderSection
