import React from 'react'
import PropTypes from 'prop-types'
import isMobile from 'is-mobile'
import ProjectText from '../projectText'
import LazyLoad from 'react-lazyload'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from 'styled-components'
import 'animate.css/animate.min.css'
import {
  Photo,
  TextSection,
  IMG,
  ProjectContainer,
  ProjectWrapper,
} from './styles'

const ScrollAnimationStyled = styled(ScrollAnimation)`
  width: 100%;
`

const ProjectInfo = ({
  title,
  description,
  slug,
  projectLink,
  linkType,
  link_text,
  photo,
  inverted,
}) => {
  let Project = (
    <ProjectContainer flexWrap="wrap">
      <ProjectWrapper inverted={inverted}>
        {renderPhoto(photo, inverted)}
        {renderText(
          title,
          description,
          slug,
          projectLink,
          linkType,
          link_text,
          inverted
        )}
      </ProjectWrapper>
    </ProjectContainer>
  )

  if (inverted && !isMobile()) {
    Project = (
      <ProjectContainer flexWrap="wrap">
        <ProjectWrapper>
          {renderText(
            title,
            description,
            slug,
            projectLink,
            linkType,
            link_text,
            inverted
          )}
          {renderPhoto(photo, inverted)}
        </ProjectWrapper>
      </ProjectContainer>
    )
  }

  return Project
}

const renderText = (
  title,
  description,
  slug,
  projectLink,
  linkType,
  link_text,
  inverted
) => (
  <TextSection
    tabletText
    width={[1, 1 / 2, 2 / 5, 2 / 5]}
    mb={['80px', 0, 0, 0]}
    mt={['20px', 0, 0, 0]}
    inverted={inverted}
  >
    <ProjectText
      title={title}
      description={description}
      slug={slug}
      projectLink={projectLink}
      linkType={linkType}
      linkText={link_text}
    />
  </TextSection>
)

const renderPhoto = (photo, inverted) => {
  const srcSmall = `${photo}?w=842`
  const srcLarge = `${photo}?w=1200`
  return (
    <Photo inverted={inverted}>
      <LazyLoad offset={100}>
        <ScrollAnimationStyled animateIn="fadeIn">
          <picture>
            <source
              srcSet={srcSmall}
              media="(min-width: 0px) and (max-width: 767px) and (min-resolution: 190dpi)"
            ></source>
            <source
              srcSet={srcLarge}
              media="(min-width: 768px) and  (min-resolution: 190dpi)"
            ></source>
            <source srcSet={photo}></source>
            <IMG src={photo} />
          </picture>
        </ScrollAnimationStyled>
      </LazyLoad>
    </Photo>
  )
}

ProjectInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  linkType: PropTypes.string,
  photo: PropTypes.string,
  inverted: PropTypes.bool,
}

export default ProjectInfo
