import React from 'react'
import PropTypes, { arrayOf, objectOf, shape, string } from 'prop-types'
import { Box } from '@rebass/grid'
import { HighlightWrapper, IMG, Photo } from './highlight/styles'
import HighlightText from './highlight/highlightText'

export const HighlightShape = shape({
  elements: objectOf(
    shape({
      title: objectOf(
        shape({
          value: string,
        })
      ),
      description: objectOf(
        shape({
          value: string,
        })
      ),
      image: objectOf(
        shape({
          value: arrayOf(
            shape({
              url: string,
            })
          ),
        })
      ),
    })
  ),
})

const Highlight = ({ highlight, inverted }) => {
  const photo = highlight.elements.image.value[0].url

  const { title, description } = highlight.elements

  const textWidth = inverted ? [1, 0.6] : [1, 0.4]
  const imageWidth = inverted ? [1, 0.6] : [1, 0.4]

  return (
    <HighlightWrapper inverted={inverted}>
      <Box width={textWidth} style={{ display: 'flex', flex: 2 }}>
        <HighlightText title={title.value} description={description.value} />
      </Box>
      <Box
        width={imageWidth}
        style={{
          height: '100%',
          flex: 4,
          display: 'flex',
          justifyContent: inverted ? 'start' : 'end',
        }}
      >
        {renderPhoto(photo, inverted)}
      </Box>
    </HighlightWrapper>
  )
}

const renderPhoto = (photo, inverted) => {
  const srcSmall = `${photo}?w=842`
  const srcLarge = `${photo}?w=1200`
  return (
    <Photo inverted={inverted}>
      <picture>
        <source
          srcSet={srcSmall}
          media="(min-width: 0px) and (max-width: 767px) and (min-resolution: 190dpi)"
        ></source>
        <source
          srcSet={srcLarge}
          media="(min-width: 768px) and  (min-resolution: 190dpi)"
        ></source>
        <source srcSet={photo}></source>
        <IMG src={photo} />
      </picture>
    </Photo>
  )
}

Highlight.propTypes = {
  highlight: HighlightShape,
  inverted: PropTypes.bool,
}

export default Highlight
