import React from 'react'
import PropTypes from 'prop-types'
import PageSection from 'components/Section/pageSection'
import Container from 'components/Container'
import ProjectInfo from './components/projectInfoHorizontal'
import withCurrentBreakpoint from 'hoc/withCurrentBreakpoint'
import QuotesSection from 'sections/Shared/quotes'
import styled from 'styled-components'
import media from 'styles/media'

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  ${media.phone`
    min-width: auto;
    width: auto;
    height: auto;
    margin-bottom: 34px;
  `}
`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin: 0 0 17px 0;

  ${media.phone`
    font-size: 24px;
    line-height: 40px;
    margin: 0 0 7px 0;
  `}
`

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 40px;
  max-width: 500px;
  margin: 0;

  ${media.phone`
    font-size: 20px;
    line-height: 28px;
  `}
`

const renderProjectInfos = (caseStudies, currentBreakpoint) => {
  caseStudies.forEach((caseStudy) => {
    const studyIndex = caseStudies.indexOf(caseStudy)
    studyIndex % 2 == 0
      ? (caseStudy.elements.inverted = false)
      : (caseStudy.elements.inverted = true)

    if (currentBreakpoint === 'phone') {
      caseStudy.elements.inverted = false
    }
  })

  return (
    <>
      {caseStudies.map(
        (
          {
            elements: {
              title,
              preview_description,
              slug,
              preview_image,
              project_link,
              link_type,
              link_text,
              inverted,
            },
          },
          i
        ) => (
          <ProjectInfo
            title={title.value}
            description={preview_description.value}
            slug={slug.value}
            projectLink={project_link.value}
            linkType={link_type.value[0].codename}
            link_text={link_text.value}
            photo={preview_image.value[0] && preview_image.value[0].url}
            inverted={inverted}
            key={i}
          />
        )
      )}
    </>
  )
}

const WorkSection = ({
  currentBreakpoint,
  elements: {
    case_studies,
    testimonials,
    case_studies_title,
    case_studies_description,
  },
}) => {
  const firstThreeCaseStudies = case_studies.slice(0, 3)
  const lastFiveCaseStudies = case_studies.slice(3, 8)

  return (
    <PageSection padding>
      <Container>
        <TextBox>
          <Title>{case_studies_title.value}</Title>
          <Subtitle style={{ textAlign: 'center' }}>
            {case_studies_description.value.replace(/<\/?p>/g, '')}
          </Subtitle>
        </TextBox>
      </Container>
      <Container>
        {renderProjectInfos(firstThreeCaseStudies, currentBreakpoint)}
      </Container>
      <QuotesSection testimonials={testimonials} />
      <Container>
        {renderProjectInfos(lastFiveCaseStudies, currentBreakpoint)}
      </Container>
    </PageSection>
  )
}

WorkSection.propTypes = {
  elements: PropTypes.object,
  currentBreakpoint: PropTypes.string,
}

export default withCurrentBreakpoint(WorkSection)
