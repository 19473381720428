import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Section from 'components/Section'
import media from 'styles/media'
import PageTransitionLink from 'components/PageTransitionLink'
import { color } from 'const/colors'

const CenteredSection = styled(Section)`
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  padding: 22px 13px;
  display: flex;
  cursor: pointer;

  ${media.phone`
    font-size: 18px;
    text-align: left;
    line-height: 28px;
    width: 100%;
    padding: 16px;
    margin: 0;
  `}
  &:focus {
    outline: 0;
  }
`

class CtaSection extends React.Component {
  render() {
    const { btnText, link } = this.props
    return (
      <PageTransitionLink to={link}>
        <CenteredSection
          width={1}
          bgColor={color.hotPink.o100}
          hbgColor={color.warmPink.o100}
          color={color.white.o100}
        >
          {btnText}
        </CenteredSection>
      </PageTransitionLink>
    )
  }
}

CtaSection.propTypes = {
  btnText: PropTypes.string,
  link: PropTypes.string,
}

export default CtaSection
