import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from 'layouts/Default'
import HeaderSection from 'sections/Work/header'
import WorkSection from 'sections/Work/work'
import ContactSection from 'sections/Shared/ContactSection'
import ActionSection from 'sections/Work/action'
import PartnersSection from 'sections/About/partners'
import { color } from 'const/colors'
import media from 'styles/media'
import HighlightsSection from '../sections/Work/highlights'

const circleRadius = '75vw'

const WorkSectionStyled = styled(WorkSection)`
  min-height: 100vh;
`

const HeroSection = styled.div`
  margin-top: -125px;
  padding-top: 125px;
  position: relative;
  background-image: url('/images/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const ContentSection = styled.div`
  position: relative;
  z-index: 100;
  background-color: ${color.white.o100};
`

const OuterCircle = styled.div`
  width: ${circleRadius};
  height: ${circleRadius};
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(25%, 40%);
  border-radius: 50%;
  z-index: 1;

  ${media.phone`
    width: 100vh;
    height: 100vh;
  `}
`

const InnerCircle = styled.div`
  background-color: transparent;
  width: calc(${circleRadius} / 1.66);
  height: calc(${circleRadius} / 1.66);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(33%, 33%);
  border-radius: 50%;
  z-index: 1;

  ${media.phone`
    width: calc(100vh / 1.66);
    height: calc(100vh / 1.66);
  `}
`

const Circles = () => (
  <OuterCircle>
    <InnerCircle />
  </OuterCircle>
)

class Index extends React.Component {
  state = {
    isContactFormModalOpen: false,
  }

  toggleScrollBody = (isContactFormModalOpen) => {
    this.setState({
      isContactFormModalOpen,
    })
  }

  buildOGMetadata() {
    const { elements } = this.props.data.kenticoCloudItemHome

    return {
      title: elements.page_metadata__opengraph_title.value,
      imageUrl: elements.page_metadata__opengraph_image.value[0].url,
      description: elements.page_metadata__opengraph_description.value,
    }
  }

  render() {
    const { isContactFormModalOpen } = this.state
    const { data, ...restProps } = this.props

    return (
      <Layout
        headerProps={{ burgerColor: color.white.o100 }}
        isColorBackground
        isContactFormModalOpen={isContactFormModalOpen}
        ogMetadata={this.buildOGMetadata()}
        {...restProps}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_ROBOTS_SITE_URL}`}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: 'https://distologystudios.com/solutions',
                description: 'Engineering &amp; Design with Identity',
                brand: {
                  '@type': 'Brand',
                  name: 'Distology Studios',
                  logo: 'https://distologystudios.com/',
                },
              }),
            }}
          />
        </Helmet>
        <HeroSection>
          <HeaderSection {...data.kenticoCloudItemHome} skew={true} />
          <Circles />
        </HeroSection>
        <ContentSection>
          <HighlightsSection {...data.kenticoCloudItemHome} />
          <PartnersSection {...data.kenticoCloudItemAbout} />
          <WorkSectionStyled {...data.kenticoCloudItemHome} />
          <ContactSection toggleScrollBody={this.toggleScrollBody} />
          <ActionSection {...data.kenticoCloudItemHome} />
        </ContentSection>
      </Layout>
    )
  }
}

Index.propTypes = {
  data: PropTypes.object,
}

export default Index

export const query = graphql`
  {
    kenticoCloudItemHome {
      elements {
        supportingherotext {
          value
        }
        case_studies_title {
          value
        }
        case_studies_description {
          value
        }
        highlights {
          elements {
            description {
              value
            }
            title {
              value
            }
            image {
              value {
                url
              }
            }
          }
          system {
            id
            name
          }
        }
        accreditations {
          elements {
            target {
              value
            }
            image {
              value {
                url
              }
            }
            properties {
              value {
                codename
              }
            }
          }
          system {
            name
          }
        }
        title {
          value
        }
        tagline {
          value
          resolvedHtml
        }
        cta_text {
          value
        }
        cta_link {
          value
        }
        more_work_link {
          value
        }
        testimonials {
          elements {
            quote {
              value
            }
            name {
              value
            }
            job_position {
              value
            }
            company {
              value
            }
          }
        }
        breakout_button {
          value
        }
        breakout_url {
          value
        }
        case_studies {
          elements {
            title {
              value
            }
            preview_description {
              value
            }
            preview_image {
              value {
                url
              }
            }
            slug {
              value
            }
            project_link {
              value
            }
            link_text {
              value
            }
            link_type {
              value {
                codename
              }
            }
          }
        }
        page_metadata__opengraph_title {
          value
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
      }
    }
    kenticoCloudItemAbout {
      elements {
        partners_title {
          value
        }
        partners_tagline {
          value
        }
        partners {
          elements {
            logo {
              value {
                url
              }
            }
            tagline {
              value
            }
            slug {
              value
            }
          }
        }
      }
    }
  }
`
