import React from 'react'
import PropTypes from 'prop-types'
import CtaSection from 'components/Section/ctaSection'

const ActionSection = ({ elements: { breakout_button, breakout_url } }) => (
  <CtaSection btnText={breakout_button.value} link={breakout_url.value} />
)

export default ActionSection

ActionSection.propTypes = {
  elements: PropTypes.object,
}
